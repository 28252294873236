@import url('./bootstrap-datetimepicker.css');
@import url('./bootstrap-tagsinput.css');

@import 'bootstrap';
@import 'fontawesome4';

html,
body {
	height: 100%;
}

body {
	padding-bottom: 70px;
	padding-top: 70px;
	overflow: hidden;
}

.head-fixed tbody {
	overflow-y: auto;
}

.head-fixed tbody {
	width: 100%;
}

.head-fixed thead {
	width: 100%;
}

.head-fixed thead,
.head-fixed tbody,
.head-fixed tr,
.head-fixed td,
.head-fixed th {
	display: block;
}

.head-fixed > tbody > tr > td,
.head-fixed thead > tr > th {
	float: left;
	border-bottom-width: 0;
}

.col-size-history-detail {
	min-height: 1px;
	width: 14.2857%;
}

.col-size-events {
	min-height: 1px;
	width: 7.75%;
}

.col-size-events-icon {
	min-height: 1px;
	width: 3%;
}

.col-size-events-flag {
	min-height: 1px;
	width: 4%;
}

.col-size-row {
	min-height: 1px;
	width: 99%;
	float: left;
	position: relative;
}

.word-hyphenate {
	word-wrap: break-word;
}

#ui-overlay {
	height: 100%;
	overflow: hidden;
}

#ui-overlay > div {
	height: 100%;
}

.logo {
	position: fixed;
	bottom: 20px;
	left: 5px;
}

.logo img {
	height: 20px;
}

.login-logo img {
	height: 20px;
	margin: 20px;
}

.xhr-error {
	text-align: center;
}

/* spinning wheel */

.absoluteCenter {
	width: 50%;
	height: 50%;
	overflow: auto;
	margin: auto;
	position: relative;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin-top: 100px;
	text-align: center;
	color: #666;
}

.absoluteCenterLogin {
	margin: auto;
	position: relative;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin-top: 100px;
	text-align: center;
	color: #666;
}

.absoluteCenterBox {
	margin: auto;
	position: relative;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin-top: 20px;
	text-align: center;
	width: 70%;
	height: 70%;
	color: #666;
}

.sub-footer {
	position: fixed;
	bottom: 0;
	margin-bottom: 100px;
	width: 100%;
	text-align: center;
}

.centerLoginBox {
	float: none;
	margin-left: auto;
	margin-right: auto;
	margin-top: auto;
	margin-bottom: auto;
}

#spinner {
	position: relative;
	top: 50%;
	left: 50%;
	margin-top: 100px;
}

#floatingBarsG {
	position: relative;
	width: 40px;
	height: 50px;
}

.blockG {
	position: absolute;
	background-color: #ffffff;
	width: 6px;
	height: 16px;
	border-radius: 6px 6px 0 0;
	transform: scale(0.4);
	animation-name: fadeG;
	animation-duration: 1.04s;
	animation-iteration-count: infinite;
	animation-direction: linear;
}

#rotateG_01 {
	left: 0;
	top: 18px;
	animation-delay: 0.39s;
	transform: rotate(-90deg);
}

#rotateG_02 {
	left: 5px;
	top: 6px;
	animation-delay: 0.52s;
	transform: rotate(-45deg);
}

#rotateG_03 {
	left: 17px;
	top: 2px;
	animation-delay: 0.65s;
	transform: rotate(0deg);
}

#rotateG_04 {
	right: 5px;
	top: 6px;
	animation-delay: 0.78s;
	transform: rotate(45deg);
}

#rotateG_05 {
	right: 0;
	top: 18px;
	animation-delay: 0.9099999999999999s;
	transform: rotate(90deg);
}

#rotateG_06 {
	right: 5px;
	bottom: 5px;
	animation-delay: 1.04s;
	transform: rotate(135deg);
}

#rotateG_07 {
	bottom: 0;
	left: 17px;
	animation-delay: 1.1700000000000002s;
	transform: rotate(180deg);
}

#rotateG_08 {
	left: 5px;
	bottom: 5px;
	animation-delay: 1.3s;
	transform: rotate(-135deg);
}

@keyframes fadeG {
	0% {
		background-color: #000000;
	}

	100% {
		background-color: #ffffff;
	}
}

.modal-xl {
	width: 80%;
}

@keyframes pulse {
	0%,
	100% {
		transform: scale(1);
	}

	25% {
		transform: scale(1.2);
	}

	75% {
		transform: scale(0.8);
	}
}

.pulse {
	animation: pulse 1s 0s linear infinite;
}

.data-scrollable-list {
	overflow: auto;
}

.callout {
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 30px;
	padding-right: 30px;
	margin: 20px 0;
	border-right: solid #eee;
	border-right-width: 5px;
	border-radius: 3px;
}

.callout h4 {
	color: #000;
	margin-top: 0;
	margin-bottom: 5px;
}

.fixed-height-100 {
	height: 100%;
}

.flex.flex-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.clickable {
	cursor: pointer;
}

.slider {
	display: inline-block;
	vertical-align: middle;
	position: relative;
}
.slider.slider-horizontal {
	width: 100%;
	height: 20px;
}
.slider.slider-horizontal .slider-track {
	height: 10px;
	width: 100%;
	margin-top: -5px;
	top: 50%;
	left: 0;
}
.slider.slider-horizontal .slider-selection,
.slider.slider-horizontal .slider-track-low,
.slider.slider-horizontal .slider-track-high {
	height: 100%;
	top: 0;
	bottom: 0;
}
.slider.slider-horizontal .slider-tick,
.slider.slider-horizontal .slider-handle {
	margin-left: -10px;
	margin-top: -5px;
}
.slider.slider-horizontal .slider-tick.triangle,
.slider.slider-horizontal .slider-handle.triangle {
	border-width: 0 10px 10px 10px;
	width: 0;
	height: 0;
	border-bottom-color: #0480be;
	margin-top: 0;
}
.slider.slider-horizontal .slider-tick-label-container {
	white-space: nowrap;
	margin-top: 20px;
}
.slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
	padding-top: 4px;
	display: inline-block;
	text-align: center;
}
.slider.slider-vertical {
	height: 210px;
	width: 20px;
}
.slider.slider-vertical .slider-track {
	width: 10px;
	height: 100%;
	margin-left: -5px;
	left: 50%;
	top: 0;
}
.slider.slider-vertical .slider-selection {
	width: 100%;
	left: 0;
	top: 0;
	bottom: 0;
}
.slider.slider-vertical .slider-track-low,
.slider.slider-vertical .slider-track-high {
	width: 100%;
	left: 0;
	right: 0;
}
.slider.slider-vertical .slider-tick,
.slider.slider-vertical .slider-handle {
	margin-left: -5px;
	margin-top: -10px;
}
.slider.slider-vertical .slider-tick.triangle,
.slider.slider-vertical .slider-handle.triangle {
	border-width: 10px 0 10px 10px;
	width: 1px;
	height: 1px;
	border-left-color: #0480be;
	margin-left: 0;
}
.slider.slider-vertical .slider-tick-label-container {
	white-space: nowrap;
}
.slider.slider-vertical .slider-tick-label-container .slider-tick-label {
	padding-left: 4px;
}
.slider.slider-disabled .slider-handle {
	background-image: -webkit-linear-gradient(top, #dfdfdf 0%, #bebebe 100%);
	background-image: -o-linear-gradient(top, #dfdfdf 0%, #bebebe 100%);
	background-image: linear-gradient(to bottom, #dfdfdf 0%, #bebebe 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdfdfdf', endColorstr='#ffbebebe', GradientType=0);
}
.slider.slider-disabled .slider-track {
	background-image: -webkit-linear-gradient(top, #e5e5e5 0%, #e9e9e9 100%);
	background-image: -o-linear-gradient(top, #e5e5e5 0%, #e9e9e9 100%);
	background-image: linear-gradient(to bottom, #e5e5e5 0%, #e9e9e9 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe5e5e5', endColorstr='#ffe9e9e9', GradientType=0);
	cursor: not-allowed;
}
.slider input {
	display: none;
}
.slider .tooltip.top {
	margin-top: -36px;
}
.slider .tooltip-inner {
	white-space: nowrap;
}
.slider .hide {
	display: none;
}
.slider-track {
	position: absolute;
	cursor: pointer;
	background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #f9f9f9 100%);
	background-image: -o-linear-gradient(top, #f5f5f5 0%, #f9f9f9 100%);
	background-image: linear-gradient(to bottom, #f5f5f5 0%, #f9f9f9 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
}

.slider-selection {
	position: absolute;
	background-image: -webkit-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
	background-image: -o-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
	background-image: linear-gradient(to bottom, #f9f9f9 0%, #f5f5f5 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#fff5f5f5', GradientType=0);
	-webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 4px;
}

#sliderBarColor .slider-selection {
	background: #e0e0e0;
}
.slider-selection.tick-slider-selection {
	background-image: -webkit-linear-gradient(top, #89cdef 0%, #81bfde 100%);
	background-image: -o-linear-gradient(top, #89cdef 0%, #81bfde 100%);
	background-image: linear-gradient(to bottom, #89cdef 0%, #81bfde 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff89cdef', endColorstr='#ff81bfde', GradientType=0);
}
.slider-track-low,
.slider-track-high {
	position: absolute;
	background: transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 4px;
}
.slider-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	background-color: #bfbfbf;
	background-image: -webkit-linear-gradient(top, #149bdf 0%, #0480be 100%);
	background-image: -o-linear-gradient(top, #149bdf 0%, #0480be 100%);
	background-image: linear-gradient(to bottom, #848688 0%, #848688 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf', endColorstr='#ff0480be', GradientType=0);
	filter: none;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
		0 1px 2px rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
		0 1px 2px rgba(0, 0, 0, 0.05);
	border: 0px solid transparent;
}
.slider-handle.round {
	border-radius: 50%;
}
.slider-handle.triangle {
	background: transparent none;
}
.slider-handle.custom {
	background: transparent none;
}
.slider-handle.custom::before {
	line-height: 20px;
	font-size: 20px;
	content: '\2605';
	color: #726204;
}
.slider-tick {
	position: absolute;
	width: 20px;
	height: 20px;
	background-image: -webkit-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
	background-image: -o-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
	background-image: linear-gradient(to bottom, #f9f9f9 0%, #f5f5f5 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#fff5f5f5', GradientType=0);
	-webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	filter: none;
	opacity: 0.8;
	border: 0px solid transparent;
}
.slider-tick.round {
	border-radius: 50%;
}
.slider-tick.triangle {
	background: transparent none;
}
.slider-tick.custom {
	background: transparent none;
}
.slider-tick.custom::before {
	line-height: 20px;
	font-size: 20px;
	content: '\2605';
	color: #726204;
}
.slider-tick.in-selection {
	background-image: -webkit-linear-gradient(top, #89cdef 0%, #81bfde 100%);
	background-image: -o-linear-gradient(top, #89cdef 0%, #81bfde 100%);
	background-image: linear-gradient(to bottom, #89cdef 0%, #81bfde 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff89cdef', endColorstr='#ff81bfde', GradientType=0);
	opacity: 1;
}

.button-leftCenter {
	position: absolute;
	top: 50%;
	left: 10%;
}

.button-rightCenter {
	position: absolute;
	top: 50%;
	left: 88%;
}

.rightAlign {
	float: right;
}

.col-detail-item {
	position: relative;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	float: left;
	width: 75%;
}

.col-compare-item {
	height: 50%;
}

.sortItem.active {
	background-color: #00ffcc;
}

.scrollable {
	overflow-y: scroll;
}

.scroll-y-auto {
	overflow-y: auto;
}

.disable-color {
	color: #b8b8b8;
}

.half-height {
	height: calc(50% - 10px);
}

.listRight {
	left: auto !important;
	right: 0 !important;
}

.tabs-heading {
	padding-bottom: 0px;
	border-bottom: 0px;
	padding-right: 0px;
	padding-left: 0px;
}

.btn-menu-active {
	color: #f5ecce;
	background-color: #81bef7;
}

.panel-no-border {
	border: none;
}

/* Card layout taken from bootstrap 4.0.0 alpha release */
.card {
	position: relative;
	display: block;
	margin-bottom: 0.75rem;
	background-color: #fff;
	border: 1px solid #e5e5e5;
	border-radius: 0.25rem;
}

.card-block {
	padding: 1.25rem;
}

.card-title {
	margin-bottom: 0.75rem;
}

.card-subtitle {
	margin-top: -0.375rem;
	margin-bottom: 0;
}

.card-text:last-child {
	margin-bottom: 0;
}

.card-link:hover {
	text-decoration: none;
}

.card-link + .card-link {
	margin-left: 1.25rem;
}

.card > .list-group:first-child .list-group-item:first-child {
	border-radius: 0.25rem 0.25rem 0 0;
}

.card > .list-group:last-child .list-group-item:last-child {
	border-radius: 0 0 0.25rem 0.25rem;
}

.card-header {
	padding: 0.75rem 1.25rem;
	background-color: #f5f5f5;
	border-bottom: 1px solid #e5e5e5;
}

.card-header:first-child {
	border-radius: 0.25rem 0.25rem 0 0;
}

.card-footer {
	padding: 0.75rem 1.25rem;
	background-color: #f5f5f5;
	border-top: 1px solid #e5e5e5;
}

.card-footer:last-child {
	border-radius: 0 0 0.25rem 0.25rem;
}

.card-primary {
	background-color: #0275d8;
	border-color: #0275d8;
}

.card-success {
	background-color: #5cb85c;
	border-color: #5cb85c;
}

.card-info {
	background-color: #5bc0de;
	border-color: #5bc0de;
}

.card-warning {
	background-color: #f0ad4e;
	border-color: #f0ad4e;
}

.card-danger {
	background-color: #d9534f;
	border-color: #d9534f;
}

.card-primary-outline {
	background-color: transparent;
	border-color: #0275d8;
}

.card-secondary-outline {
	background-color: transparent;
	border-color: #ccc;
}

.card-info-outline {
	background-color: transparent;
	border-color: #5bc0de;
}

.card-success-outline {
	background-color: transparent;
	border-color: #5cb85c;
}

.card-warning-outline {
	background-color: transparent;
	border-color: #f0ad4e;
}

.card-danger-outline {
	background-color: transparent;
	border-color: #d9534f;
}

.card-inverse .card-header,
.card-inverse .card-footer {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.card-inverse .card-header,
.card-inverse .card-footer,
.card-inverse .card-title,
.card-inverse .card-blockquote {
	color: #fff;
}

.card-inverse .card-link,
.card-inverse .card-text,
.card-inverse .card-blockquote > footer {
	color: rgba(255, 255, 255, 0.65);
}

.card-inverse .card-link:focus,
.card-inverse .card-link:hover {
	color: #fff;
}

.card-blockquote {
	padding: 0;
	margin-bottom: 0;
	border-left: 0;
}

.card-img {
	border-radius: 0.25rem;
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
}

.card-img-top {
	border-radius: 0.25rem 0.25rem 0 0;
}

.card-img-bottom {
	border-radius: 0 0 0.25rem 0.25rem;
}

@media (min-width: 544px) {
	.card-deck {
		display: table;
		table-layout: fixed;
		border-spacing: 1.25rem 0;
	}
	.card-deck .card {
		display: table-cell;
		width: 1%;
		vertical-align: top;
	}
	.card-deck-wrapper {
		margin-right: -1.25rem;
		margin-left: -1.25rem;
	}
}

@media (min-width: 544px) {
	.card-group {
		display: table;
		width: 100%;
		table-layout: fixed;
	}
	.card-group .card {
		display: table-cell;
		vertical-align: top;
	}
	.card-group .card + .card {
		margin-left: 0;
		border-left: 0;
	}
	.card-group .card:first-child {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.card-group .card:first-child .card-img-top {
		border-top-right-radius: 0;
	}
	.card-group .card:first-child .card-img-bottom {
		border-bottom-right-radius: 0;
	}
	.card-group .card:last-child {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.card-group .card:last-child .card-img-top {
		border-top-left-radius: 0;
	}
	.card-group .card:last-child .card-img-bottom {
		border-bottom-left-radius: 0;
	}
	.card-group .card:not(:first-child):not(:last-child) {
		border-radius: 0;
	}
	.card-group .card:not(:first-child):not(:last-child) .card-img-top,
	.card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
		border-radius: 0;
	}
}

@media (min-width: 544px) {
	.card-columns {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
		-webkit-column-gap: 1.25rem;
		-moz-column-gap: 1.25rem;
		column-gap: 1.25rem;
	}
	.card-columns .card {
		display: inline-block;
		width: 100%;
	}
}

.panelHeadingSelect {
	color: #fff;
	background-color: #5bc0de;
	display: block;
	font-size: 14px;
	line-height: 1.42857143;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s,
		-webkit-box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.scrollable-menu {
	height: auto;
	max-height: 150px;
	overflow-x: hidden;
	width: 200px;
}

.noresize {
	resize: none;
}

.chat {
	list-style: none;
	margin: 0;
	padding: 0;
}

.chat li {
	margin-bottom: 10px;
	padding-bottom: 5px;
	border-bottom: 1px dotted #b3a9a9;
}

.chat li .chat-body p {
	margin: 0;
	color: #777777;
}

.border-radius-5 {
	border-radius: 5px;
}

.badge-notify {
	position: relative;
}

.badge-notify[data-badge]:after {
	content: attr(data-badge);
	position: absolute;
	top: -10px;
	right: -10px;
	font-size: 12px;
	background: #a91414;
	color: white;
	width: 18px;
	height: 18px;
	text-align: center;
	line-height: 18px;
	border-radius: 50%;
	box-shadow: 0 0 1px #333;
}

.list-group-item-arrow:before {
	font-family: 'Glyphicons Halflings';
	content: '\e080';
	position: absolute;
	right: 15px;
	top: 50%;
	font-size: 14px;
	line-height: 14px;
	margin-top: -7px;
	color: #ec916a;
}

.list-group-item-circle-arrow:before {
	font-family: 'Glyphicons Halflings';
	content: '\e131';
	position: absolute;
	right: 15px;
	top: 50%;
	font-size: 14px;
	line-height: 14px;
	margin-top: -7px;
	color: #7d7d7d;
}

.list-group-item-plus:before {
	font-family: 'Glyphicons Halflings';
	content: '\e081';
	position: absolute;
	right: 15px;
	top: 50%;
	font-size: 14px;
	line-height: 14px;
	margin-top: -7px;
	color: #ec916a;
}

.axis path,
.axis line {
	fill: none;
	stroke: #000;
	shape-rendering: crispEdges;
}

.axis text {
	font-family: sans-serif;
	font-size: 11px;
}

.chartText {
	font-family: sans-serif;
	font-size: 11px;
}

.focusArea {
	overflow-y: auto;
}

.line {
	fill: none;
	stroke: red;
	stroke-width: 1.5px;
}

.area {
	fill: #d3e5ff;
	clip-path: url(#clip);
}

.brush .extent {
	stroke: #fff;
	fill-opacity: 0.5;
	shape-rendering: crispEdges;
}

.focusDot {
	cursor: pointer;
}

.embedHTML {
	width: 100%;
}

.embedHTMLBody {
	padding-top: 20px;
	padding-bottom: 20px;
}

.stroke {
	fill: none;
	stroke: #000;
	stroke-width: 3px;
}

.fill {
	fill: #fff;
}

.latLongGrid {
	fill: none;
	stroke: #777;
	stroke-width: 0.5px;
	stroke-opacity: 0.5;
}

.land {
	fill: #d3e5ff;
}

.country {
	fill: none;
	stroke: #512901;
	stroke-width: 1px;
}

.orbitLine {
	fill: none;
	stroke: black;
	stroke-width: 0.5px;
}

.satOrbitPin {
	cursor: pointer;
	fill: #ec916a;
	stroke: #ec916a;
}

.selectOrbitLine {
	fill: none;
	stroke: black;
	stroke-width: 5px;
}

.satOrbitPinText {
	font-family: sans-serif;
	font-size: 11px;
}

.satOrbitPinTextShadow {
	font-family: sans-serif;
	font-size: 11px;
	stroke: white;
	stroke-width: 3.5px;
	opacity: 0.9;
}

/* home page */

.home_text_tab,
.blogItemOuter {
	max-width: 1000px;
	margin: 0 auto;
	padding: 16px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.home_carousel {
	background: #000 center center no-repeat;
	background-size: 100%;
	height: 350px;
	overflow-y: hidden;
}

.home_carousel_title_outer {
	margin: 10px 0 5px 10px;
	padding: 0 15px 0 0;
	min-width: 10px;
	width: auto;
	height: 29px;
	background: #00549f;
	border-top: 1px solid #00549f;
	border-bottom: 1px solid #00549f;
	display: block;
	float: left;
}

.home_carousel_title_inner {
	margin: 4px 0 0 0;
	padding: 0;
	background: url(./hl_arw.png) 7px 0 no-repeat;
	display: inline-block;
}

.home_carousel_title_inner h1 {
	font-size: 22px;
	line-height: 18px;
	font-weight: normal;
	color: #fff;
	text-decoration: none;
	margin: 0 0 0 25px;
	padding: 0;
}

.home_carousel_text {
	margin: 5px 0 0 11px;
	width: 332px;
	height: 80px;
	clear: both;
	display: block;
}

.home_carousel_text p {
	color: #fff;
	font-size: 14px;
	font-weight: normal;
	text-decoration: none;
}

.truncate_text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* plots */

.tick line {
	opacity: 0.2;
}

.icon-unread {
	color: #de2d2d;
}
