.bootstrap-tagsinput {
	background-color: #fff;
	border: 1px solid #ccc;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	display: inline-block;
	padding: 4px 6px;
	color: #555;
	vertical-align: middle;
	border-radius: 4px;
	width: 100%;
	max-width: 100%;
	min-width: 100%;
	line-height: 22px;
	cursor: text;
}
.bootstrap-tagsinput input {
	border: none;
	box-shadow: none;
	outline: none;
	background-color: transparent;
	padding: 0 6px;
	margin: 0;
	width: auto;
	max-width: inherit;
}
.bootstrap-tagsinput.form-control input::-moz-placeholder {
	color: #777;
	opacity: 1;
}
.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
	color: #777;
}
.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
	color: #777;
}
.bootstrap-tagsinput input:focus {
	border: none;
	box-shadow: none;
}
.bootstrap-tagsinput .tag {
	margin-right: 2px;
	color: white;
}
.bootstrap-tagsinput .tag [data-role='remove'] {
	margin-left: 8px;
	cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role='remove']:after {
	content: 'x';
	padding: 0px 2px;
}
.bootstrap-tagsinput .tag [data-role='remove']:hover {
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
		0 1px 2px rgba(0, 0, 0, 0.05);
}
.bootstrap-tagsinput .tag [data-role='remove']:hover:active {
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
	margin-bottom: 0;
}

.twitter-typeahead .tt-hint {
	display: none;
}

.tt-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	font-size: 14px;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip: padding-box;
	cursor: pointer;
}

.tt-suggestion {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: normal;
	line-height: 1.428571429;
	color: #333333;
	white-space: nowrap;
}

.tt-suggestion:hover,
.tt-suggestion:focus {
	color: #ffffff;
	text-decoration: none;
	outline: 0;
	background-color: #428bca;
}
