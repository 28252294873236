// used by bootstrap-sass, otherwise uses a default of "../fonts/bootstrap/"
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';

@import '~bootstrap-sass';

img {
	max-width: 100% !important;
	max-height: 100%;
}

.col-lg-1-5,
.col-lg-2-25,
.col-lg-8-75,
.col-lg-9-75,
.col-lg-10-75,
.col-lg-11-75,
.col-lg-12,
.col-lg-very-small {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

@media (min-width: 1200px) {
	.col-lg-1-5,
	.col-lg-2-25,
	.col-lg-8-75,
	.col-lg-9-75,
	.col-lg-10-5,
	.col-lg-11-75,
	.col-lg-very-small {
		float: left;
	}

	.col-lg-11-75 {
		width: 98%;
	}

	.col-lg-10-5 {
		width: 88%;
	}

	.col-lg-9-75 {
		width: 81%;
	}

	.col-lg-8-75 {
		width: 73%;
	}

	.col-lg-2-25 {
		width: 19%;
	}

	.col-lg-1-5 {
		width: 12%;
	}

	.col-lg-very-small {
		width: 1%;
	}
}

.panel {
	overflow: auto;
}
